<template>
  <div id="subject-badge-detail" v-if="badgeDetail">
    <defaultTemplate>
      <div class="pd-x-5 pd-y-3">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: `/subject` }">
            <span class="text-all font-12">My Course</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: `/subject/${this.SubjectById}` }"
            ><span class="font-12 text-all"
              >{{ badgeDetail.courseName }} ({{ badgeDetail.courseId }})</span
            ></el-breadcrumb-item
          >
          <el-breadcrumb-item
            ><span class="font-12 text-breadcrumb">
              {{ badgeDetail.courseId }}.{{ badgeDetail.skillType }}</span
            ></el-breadcrumb-item
          >
        </el-breadcrumb>
        <badgeDetail />
      </div>
    </defaultTemplate>
  </div>
</template>

<script>
import { HTTP } from "@/service/axios";
import defaultTemplate from "@/template/default.vue";
import badgeDetail from "@/components/BadgeDetail";
export default {
  name: "SubjectBadgeDetail",
  components: {
    defaultTemplate,
    badgeDetail,
  },
  mounted() {
    this.getBadgeById();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    SubjectById() {
      return this.$route.params.subid;
    },
    badgeById() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      badgeDetail: null,
    };
  },
  methods: {
    getBadgeById() {
      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.get(`user/badge/${this.badgeById}`)
        .then((res) => {
          if (res.data.status == 200) {
            this.badgeDetail = res.data.obj[0];
          }
        })
        .catch((e) => {
          console.log(e);
          this.alertCatchError(e.response.status);
        });
    },
  },
};
</script>
